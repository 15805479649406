import React from 'react'

import {
  Brands,
  Hero,
  HomeServices,
  Notification,
  Page,
} from '@components'
import Image from '@assets/images/contact.jpg'

const metaData = {
  title: 'home.seo.title',
  description: 'home.seo.description',
  image: Image,
}

const Home = (props) => {
  const { locale } = props.pageContext

  return (
    <Page
      locale={locale}
      meta={metaData}
    >
      <Hero/>
      <Notification locale={locale} />
      <HomeServices />
      <Brands />
    </Page>
  )
}

export default Home
